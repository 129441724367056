<template>
    <div class="d-block" v-bind:style="{ 'overflow' : 'hidden' }">
        <div class="row align-items-center justify-content-center mx-0">
            <div class="col-12 col-md-5 col-lg-6 col-xl-4 px-0">

                <div class="d-block px-md-6 px-4 py-md-6 py-md-5 py-4 vh-100" v-bind:style="{ 'overflow-y' : 'auto' }">
                    <!-- Logo -->
                    <div class="mb-lg-5 mb-4">
                        <router-link to="/">
                            <img :src="school_info.logo" alt="Logo PPDB" style="width:50%">
                            </router-link>
                    </div>

                    <!-- Heading -->
                    <h1 class="display-4 text-left mb-4">
                        Daftar
                    </h1>

                    <!-- Form -->
                    <form v-on:submit.prevent="handleSubmit">
                        <!-- Email address -->
                        <div class="form-group">
                            <!-- Label -->
                            <label>Email</label>
                            <!-- Input -->
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><span class="fe fe-mail"></span></span>
                                </div>
                                <input type="text" class="form-control"
                                    :class="{ 'is-invalid': submitted && $v.username.$error }" v-model="username"
                                    placeholder="sample@mail.com">
                            </div>
                            <div v-if="submitted && !$v.username.required" class="invalid-feedback">Email
                                tidak boleh kosong</div>
                            <div v-if="submitted && !$v.username.email" class="invalid-feedback">Format Email
                                tidak valid</div>
                        </div>

                        <!-- Fullname -->
                        <div class="form-group">
                            <!-- Label -->
                            <label>Nama Lengkap Bakal Calon Peserta</label>
                            <!-- Input -->
                            <div class="input-group">
                                 <div class="input-group-prepend">
                                    <span class="input-group-text"><span class="fe fe-user"></span></span>
                                </div>
                                <input type="text" class="form-control"
                                    :class="{ 'is-invalid': submitted && $v.fullname.$error }" v-model="fullname"
                                    placeholder="Masukan Nama Lengkap">
                            </div>
                            <div v-if="submitted && !$v.fullname.required" class="invalid-feedback">Nama Lengkap Bakal Calon Peserta
                                tidak boleh kosong</div>
                        </div>
                        
                        <!-- Password -->
                        <div class="form-group">
                            <div class="row">
                                <div class="col">
                                    <label>Kata Sandi</label>
                                </div>
                                <div class="col-auto"></div>
                            </div>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><span class="fe fe-lock"></span></span>
                                </div>
                                <input type="password" class="form-control"
                                    :class="{ 'is-invalid': submitted && $v.password.$error }" v-model="password"
                                    placeholder="Masukan kata sandi">
                                <div v-if="submitted && !$v.password.required" class="invalid-feedback">
                                    Kata sandi tidak boleh kosong</div>
                            </div>
                        </div>

                        <!-- Retype Password -->
                        <div class="form-group">
                            <div class="row">
                                <div class="col">
                                    <label>Ulang Kata Sandi</label>
                                </div>
                                <div class="col-auto"></div>
                            </div>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><span class="fe fe-lock"></span></span>
                                </div>
                                <input type="password" class="form-control"
                                    :class="{ 'is-invalid': submitted && $v.retype_password.$error }" v-model="retype_password"
                                    placeholder="Ulangi kata sandi">
                                <div v-if="submitted && !$v.retype_password.required" class="invalid-feedback">
                                    Ulangi Kata sandi tidak boleh kosong</div>
                            </div>
                        </div>

                        <button type="submit" class="btn btn-lg btn-block btn-primary mb-3">
                            Daftar Sekarang
                        </button>

                        <!-- Signup -->
                        <div class="text-center">
                            <div class="text-muted text-center">
                                Sudah punya akun? <router-link to="/sign-in">Masuk disini</router-link>
                            </div>
                        </div>

                        <div class="d-block text-center mt-4">
                            <router-link to="/"><span class="fe fe-home mr-2"></span>Kembali ke beranda</router-link>
                        </div>

                        <div class="d-none pt-3">
                            <div class="d-block font-weight-bold">Menemukan masalah?</div>
                            <div class="d-block">Hubungi tim handal kami <a href="#">disini</a></div>
                        </div>

                        <div class="d-block pt-4 text-center">
                            <div class="d-block text-sm text-muted">Scola 2021. All Right Reserved</div>
                        </div>

                    </form>
                </div>

            </div>
            <div class="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block px-0">
                <!-- Image -->
                <div class="bg-cover vh-100 mt-n1 mr-n3" v-bind:style="{ 'background-image': 'url(' + school_info.banner_signup + ')' }">
                </div>

            </div>
        </div> <!-- / .row -->
    </div>
</template>
<script>
    import axios from "axios";
    import {required, email} from "vuelidate/lib/validators";
    import { mapState } from 'vuex'

    export default {
        name: 'Signin',
        metaInfo: {
            title: 'SchoolPro',
            titleTemplate: '%s - Sign Up'
        },
        mounted() {
            this.$store.dispatch('loadSchoolInfo');
        },
        computed: mapState(['school_info']),
        data() {
            return {
                username: "",
                password: "",
                fullname: "",
                retype_password: "",
                submitted: false
            }
        },
        validations: {
            username: {
                required,
                email
            },
            password: {
                required
            },
            fullname: {
                required
            },
            retype_password: {
                required
            },
        },
        methods: {
            handleSubmit() {
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form daftar',
                    });
                    return;
                } else {
                    this.$http.post(this.$apiconfig + 'website/auth/registrasi', {
                            username: this.username,
                            password: this.password,
                            fullname: this.fullname,
                            retype_password: this.retype_password
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Berhasil',
                                    text: response.data.message,
                                    confirmButtonText: 'OK',
                                    showCancelButton: false,
                                }).then((result) => {
                                    if (result.value) {        
                                        const token = response.data.key;
                                        localStorage.setItem('auth_token', token);
                                        axios.defaults.headers.common['Authorization'] = token;
                                        this.$router.push('/calon-siswa/pengaturan-akun');
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'error',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                        })
                        .catch(function (error) {
                            // Swal.fire('Uh oh!', 'Please check your internet connection.', 'error')
                            console.debug("Login issue: " + error)
                        });
                }
            },
            underMaintenace: function() {
                alert("Fitur sedang dalam maintenance");
            }
        }
    }
</script>